<template>
  <v-container fluid>
    <div class="tabs is-centered">
      <ul>
        <li :class="{'is-active': activeIndex === item.name}"
            v-for="(item,index) in tabsData" :key="index" @click="changeTabs(item)">
          <a class="label-input" v-if="$i18n.locale === 'en'">
            {{ item.name }}
          </a>
          <a class="label-input" v-else>{{ item.nameEn }}</a>
        </li>
      </ul>
    </div>
    <div class="from-content" v-show="activeIndex === 'Weekly'">
      <Weekly :activeTabs="this.activeIndex"/>
    </div>

    <div class="from-content" v-show="activeIndex === 'Monthly'">
      <Monthly :activeTabs="this.activeIndex"/>
    </div>

    <div class="from-content" v-show="activeIndex === 'Yearly'">
      <Yearly :activeTabs="this.activeIndex"/>
    </div>

    <div class="from-content" v-show="activeIndex === 'Specify'">
      <Specify :activeTabs="this.activeIndex"/>
    </div>
  </v-container>
</template>

<script>
import Weekly from "./RestDayTabs/Weekly";
import Monthly from "./RestDayTabs/Monthly";
import Yearly from "./RestDayTabs/Yearly";
import Specify from "./RestDayTabs/Specify";

export default {
  components: {
    Weekly,
    Monthly,
    Yearly,
    Specify
  },
  data() {
    return {
      activeIndex: 'Weekly',
      tabsData: [
        {
          name: "Weekly",
          nameEn: "ວັນພັກປະຈຳອາທິດ"
        },
        {name: "Monthly", nameEn: "ວັນພັກປະຈຳເດືອນ"},
        {name: "Yearly", nameEn: "ວັນພັກປະຈຳປີ"},
        {name: "Specify", nameEn: "ວັນພັກຕາມກຳນົດ"},
      ]
    }
  },
  methods: {
    changeTabs(item) {
      this.activeIndex = item.name;
    }
  },
  created() {
  }
}
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: red;
  padding: 40px 50px 200px;

  .from {
    width: 800px;
    height: 500px;
  }
}
</style>
