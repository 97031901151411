<template>
  <div class="mainWeekly">
    <div class="week-right">
      <v-form class="section-week-form" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
                outlined
                dense
                :label="$t('restDay.name')"
                v-model="data.name"
                :hint="`${server_errors.name}`"
                persistent-hint
            >

            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <!-- <div class="card-week-right">
        <v-row class="card-circle-weekly">
          <div class="circle-week" v-for="(item,index) in monthlyArray" :key="index" @click="changeMonthly(item)">
            <p :class="{'circle-active': activeIndex === item}">{{ item + 1 }}</p>
          </div>
        </v-row>
      </div> -->
      <div class="card-week-right">
        <v-row class="card-circle-weekly">
          <div class="circle-week" v-for="(item, index) in monthlyArray" :key="index" @click="toggleMonthly(index)">
            <!-- <p :class="{ 'circle-active': isSelected(index) }">{{ item + 1 }}</p> -->
            <p v-if="isSelected(index)" class="has-border circle-active">{{ item + 1 }}</p>
            <p v-else class="has-border">{{ item + 1 }}</p>
          </div>
        </v-row>
      </div>
      <div class="week-btn">
        <v-btn class="btn-save-change" :loading="btnLoading" @click="saveChange">
          {{ $t("restDay.save") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Weekly",
  props: {
    activeTabs: {},
  },
  data() {
    return {
      // activeIndex: new Date().getDate() - 1,
      selectedIndices: [],
      monthlyArray: [],
      btnLoading: false,
      data: {
        name: "",
        value: "",
      },
      server_errors: {
        name: "",
        type: "",
        value: ""
      }
    }
  },
  methods: {
    toggleMonthly(index) {
      const selectedIndex = this.selectedIndices.indexOf(index);
      if (selectedIndex === -1) {
        this.selectedIndices.push(index);
      } else {
        this.selectedIndices.splice(selectedIndex, 1);
      }
    },
    isSelected(index) {
      return this.selectedIndices.includes(index);
    },
    changeMonthly(item) {
      this.activeIndex = item;
    },
    resetForm() {
      this.$refs.form.reset();
    },
    saveChange() {
      const data = {
        name: this.data.name,
        value:this.selectedIndices.sort((a, b) => a - b).map((index) => this.monthlyArray[index] + 1),
        type: this.activeTabs.toLowerCase(),

      }
      
      this.btnLoading = true;
      this.$axios.post(`company/rest-day`, data).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.btnLoading = false;
          this.resetForm();
          this.activeIndex = 0;
          this.$router.push({
            name: "restDay.index",
          }).catch(() => {

          })
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },

  created() {
    this.monthlyArray = Array.from(Array(31).keys());
  }
}
</script>

<style scoped lang="scss">
.colorred{
  background-color: red;
}
.mainWeekly {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .week-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;

    .section-week-form {
      width: 100%;
      height: auto;

    }

    .card-week-right {
      padding: 10px;
      width: 100%;
      height: auto;
      //  background-color: rgba(255, 255, 255,0.6);
      // background-color: #f6e58d;
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;

      .card-circle-weekly {
        width: 100%;
        height: 100%;
        // margin: 10px;
        margin: 10px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 20px;
        cursor: pointer;

        .circle-week {
          width: 40px;
          height: 40px;
          background-color: white;
          border-radius: 50%;
          margin: 8px;
          text-align: center;
          padding-top: 8px;
          color: #6e6d6d;
          transition: ease 0.2s;
        }

        .has-border{
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;
          // margin: 5px;
          text-align: center;
          padding-top: 8px;
          margin: -8px 0;
          color: #6e6d6d;
        }

        .circle-active {
          background-color: #18BBEC;
          color: white;
          
        }
      }

      .circle-week:hover {
        width: 40px;
        height: 40px;
        background-color: #18BBEC;
        border-radius: 50%;
        margin: 5px;
        text-align: center;
        padding-top: 8px;
        color: white;
        transition: ease 0.1s;
      }
    }

    .week-btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;


    }
  }
}
</style>

