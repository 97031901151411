<template>
  <div class="mainWeekly">
    <div class="week-right">
      <v-form class="section-week-form" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
                class="mt-2"
                outlined
                dense
                :label="$t('restDay.name')"
                v-model="data.name"
                :hint="`${server_errors.name}`"
                persistent-hint
            >

            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <div class="card-week-right">
        <div class="circle-week" v-for="(item,index) in weekly" :key="index" @click="changeWeeks(item)">
          <p :class="{'circle-active': activeIndex === item.name}">{{ item.name }}</p>
        </div>
      </div>
      <div class="week-btn">
        <v-btn class="btn-save-change" @click="saveChange()" :loading="btnLoading">
          {{ $t('restDay.save') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Weekly",
  props: {
    activeTabs: {}
  },
  data() {
    return {
      btnLoading: false,
      isActive: true,
      activeIndex: 'MON',
      weekly: [
        {name: "MON"},
        {name: "TUE"},
        {name: "WED"},
        {name: "THU"},
        {name: "FRI"},
        {name: "SAT"},
        {name: "SUN"},

      ],

      data: {
        name: "",
      },
      server_errors: {
        name: "",
        type: "",
        value: ""
      }
    }
  },
  methods: {
    changeWeeks(item) {
      this.activeIndex = item.name;
    },
    resetForm() {
      this.$refs.form.reset();
    },

    saveChange() {
      this.btnLoading = true;
      const weeklyData = {
        name: this.data.name,
        type: this.activeTabs.toLowerCase(),
        value: this.activeIndex,
      }
      
      this.$axios.post(`company/rest-day`, weeklyData).then((res) => {
        console.log(res.data)
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.btnLoading = false;
          this.resetForm();
          this.activeIndex = 0;
          this.$router.push({
            name: "restDay.index",
          }).catch(() => {

          })
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
}
</script>

<style scoped lang="scss">
.mainWeekly {
  width: 900px;
  height: auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: red;

  .week-right {
    width: 600px;
    height: auto;
    display: flex;
    // background-color: yellow;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;

    .section-week-form {
      width: 100%;
      height: auto;

    }

    .card-week-right {
      width: 100%;
      height: 240px;
      background-color: rgba(254, 202, 87, 0.8);
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
      padding: 20px 100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      border-radius: 4px;

      .circle-week {
        width: 60px;
        height: 60px;
        background-color: #fcf6f6;
        // border: 2px solid #18BBEC;
        border-radius: 50%;
        transition: ease-in 0.3s;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #6e6d6d;
        margin: 10px;

        p {
          display: flex;
          justify-content: center;
          margin: 0 auto;
          font-size: 16px;
          font-family: $font-family;
          font-weight: 500;

        }

      }

      .circle-active {
        width: 60px;
        height: 60px;
        background-color: #18BBEC;
        border: none;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }

      .circle-week:hover {
        background-color: #18BBEC;
        transform: scale(1.08);
        border: none;

        p {
          color: white;
        }
      }
    }

    .week-btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      position: relative;

    }
  }
}
</style>
