<template>
  <div class="mainWeekly">
    <div class="week-right">
      <v-form class="section-week-form" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
                outlined
                dense
                :label="$t('restDay.name')"
                v-model="data.name"
                :hint="`${server_errors.name}`"
                persistent-hint
            >

            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <div class="card-week-right">
        <v-row justify="center">
          <v-date-picker
              multiple
              :show-current="false"
              width="100%"
              color="green lighten-1"
              v-model="picker"
              elevation="1"
          ></v-date-picker>
        </v-row>
      </div>
      <div class="week-btn">
        <v-btn class="btn-save-change" :loading="btnLoading" @click="saveChange">
          {{ $t('restDay.save') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "Weekly",
  props: {
    activeTabs: {}
  },
  data() {
    return {
      // picker: new Date().toISOString().substr(0, 10),
      picker: [new Date().toISOString().substr(0, 10)],
      btnLoading: false,
      data: {
        name: "",
        value: "",
      },
      server_errors: {
        name: "",
        type: "",
        value: ""
      }
    }
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    saveChange() {
      const data = {
        name: this.data.name,
        // value: moment(this.picker).format('MM-DD'),
        value: this.picker.sort((a,b) => new Date(a) - new Date(b)).map(date => moment(date).format('MM-DD').toString()),
        type: this.activeTabs.toLowerCase(),

      }
      
      this.btnLoading = true;
      this.$axios.post(`company/rest-day`, data).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.btnLoading = false;
          this.resetForm();
          this.activeIndex = 0;
          this.$router.push({
            name: "restDay.index",
          }).catch(() => {

          })
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
}
</script>

<style scoped lang="scss">
.mainWeekly {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .week-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;

    .section-week-form {
      width: 100%;
      height: auto;

    }

    .card-week-right {
      padding: 10px;
      //width: 100%;
      //height: auto;
      //box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
      //padding: 20px 100px;
      //display: flex;
      //flex-wrap: wrap;
      //justify-content: space-evenly;
      //border-radius: 4px;


    }

    .week-btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;


    }
  }
}
</style>
